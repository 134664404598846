/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { Button, DialogActions } from '@mui/material';

import { FormattedMessage } from 'react-intl';
import React, { useCallback } from 'react';
import { ConfirmationButtonProps } from '../../../GanttPeriod/GanttPeriodChangeDialog/GanttPeriodChangeDialogActions';
import {
    DispatcherMissionSlotDto,
    FlightMissionCreateDto,
    FlightMissionUpdateDto,
    KeyGetGetAllMissions,
    MissionType,
    useMutationPatchUpdateFlightMission,
    useMutationPostCreateFlightMission,
} from '../../../../../../backend/gen';
import { toast } from 'react-toastify';
import { errorToastConfig, successToastConfig } from '../../../../../../utils/constants';
import { UseMutationOptions, useQueryClient } from 'react-query';
import LoadingButton from '@mui/lab/LoadingButton';
import { convertTimestampToHHMM } from '../../../../../../utils/data.utils';
import { MissionFormDialogCancellationButtonProps } from '../../MissionFormDialogContentAndActionsContainer';

export function FlightMissionDialogActions({
    closeDialog,
    mission,
    isCreating,
}: {
    closeDialog: () => void;
    mission: Partial<DispatcherMissionSlotDto>;
    isCreating: boolean;
}) {
    const queryClient = useQueryClient();
    const options: UseMutationOptions<any, any, any> = {
        onSuccess: async () => {
            await queryClient.invalidateQueries(KeyGetGetAllMissions);
            closeDialog();
            toast(<FormattedMessage id={'flightMissionForm.success'} />, successToastConfig);
        },
        onError: () => {
            toast(<FormattedMessage id={'dispatcher.createMissionRequestError'} />, errorToastConfig);
        },
    };
    const { mutateAsync: createFlightMission, isLoading: isLoadingPost } = useMutationPostCreateFlightMission(options);
    const { mutateAsync: updateFlightMission, isLoading: isLoadingPatch } =
        useMutationPatchUpdateFlightMission(options);

    function isFormValid(mission: Partial<DispatcherMissionSlotDto>) {
        if (mission?.type === MissionType.EX_STAFF) {
            return mission.pickUpLocation && mission.dropOffLocation && mission.staffNum > 0 && mission.refTime;
        } else if (mission?.type === MissionType.EX_PAX) {
            return mission.pickUpLocation && mission.dropOffLocation && mission.refTime;
        } else if (mission?.type === MissionType.STAFF) {
            return mission.staffNum && mission.refTime;
        } else {
            return mission?.flight?.flightNumber;
        }
    }

    const onClickCreateMission = useCallback(() => {
        const createMissionDto: FlightMissionCreateDto = {
            flightId: mission.flight?.flightId,
            staff: mission.type === MissionType.STAFF || mission.type === MissionType.EX_STAFF,
            staffNum: mission.staffNum,
            startTime: convertTimestampToHHMM(mission.refTime),
            exceptional: mission.type === MissionType.EX_PAX || mission.type === MissionType.EX_STAFF,
            dropOffLocation: mission.dropOffLocation,
            pickUpLocation: mission.pickUpLocation,
        };
        createFlightMission({ flightMissionCreateDto: createMissionDto });
    }, [mission, createFlightMission]);

    const onClickUpdateMission = useCallback(() => {
        const updateMissionDto: FlightMissionUpdateDto = {
            comment: mission.comment,
            dropOffLocation: mission.dropOffLocation,
            pickUpLocation: mission.pickUpLocation,
            staffNum: mission.staffNum,
            startTime: convertTimestampToHHMM(mission.refTime),
        };
        updateFlightMission({ mission: mission.missionId, flightMissionUpdateDto: updateMissionDto });
    }, [mission, updateFlightMission]);

    return (
        <DialogActions>
            <Button {...MissionFormDialogCancellationButtonProps} onClick={closeDialog}>
                <FormattedMessage id="confirm.default.cancel" />
            </Button>
            <LoadingButton
                {...ConfirmationButtonProps}
                onClick={isCreating ? onClickCreateMission : onClickUpdateMission}
                loading={isCreating ? isLoadingPost : isLoadingPatch}
                disabled={isLoadingPost || isLoadingPatch || !isFormValid(mission)}
            >
                <FormattedMessage
                    id={isCreating ? 'flightMissionForm.createMission' : 'flightMissionForm.editMission'}
                />
            </LoadingButton>
        </DialogActions>
    );
}
