/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { memo } from 'react';
import { OnDemandReportDto } from '../../../../backend/gen';
import { ganttPxPerMs } from './gantt.constants';
import { Box, styled, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { FormattedMessage } from 'react-intl';

export const RequestedVehicleReportMarker = memo(function RequestedVehicleReportMarker({
    report,
    from,
}: {
    report: OnDemandReportDto;
    from: number;
}) {
    const reportTime = new Date(report?.at || 0).getTime();
    const left = (reportTime - from) * ganttPxPerMs;

    return (
        <Box position="absolute" left={`${left}px`} top="0" zIndex={3}>
            <Tooltip
                title={
                    <FormattedMessage
                        id={'dispatcher.gantt.onDemandReportDue'}
                        values={{ time: format(report.at, 'HH:mm') }}
                    />
                }
                placement={'right'}
            >
                <StyledSummarizeIcon color="action" fontSize={'medium'} />
            </Tooltip>
        </Box>
    );
});

const StyledSummarizeIcon = styled(SummarizeIcon)`
    position: absolute;
    top: -37px;
    left: -8px;
    cursor: pointer;
`;
