/**
 * ******************************************************************************
 * Copyright Innov'ATM all rights reserved. This software is the property of
 * Innov'ATM and may not be used in any manner except under a license agreement
 * signed with Innov'ATM.
 * *******************************************************************************
 */

import { useState, useEffect, useCallback } from 'react';
import { safeParse } from '../utils/data.utils';
import isEqual from 'lodash/fp/isEqual';

export const getStoredValue = <T>(storageKey: string, initialState: T): T => {
    const savedValue = localStorage.getItem(storageKey);
    return savedValue == null ? initialState : safeParse(savedValue);
};

export const useSetStoredValue = <T>(storageKey: string) => {
    return useCallback(
        (value: T) => {
            localStorage.setItem(storageKey, JSON.stringify(value));
        },
        [storageKey],
    );
};

export const usePersistedState = <T>(
    storageKey: string,
    initialState: T,
): [T, React.Dispatch<React.SetStateAction<T>>] => {
    const [value, setValue] = useState<T>(() => getStoredValue(storageKey, initialState));

    // Store
    useEffect(() => {
        localStorage.setItem(storageKey, JSON.stringify(value));
    }, [initialState, storageKey, value]);

    // Sync value back
    useEffect(() => {
        const onStorageChange = () => {
            const newValue = getStoredValue(storageKey, initialState);
            if (!isEqual(value, newValue)) {
                setValue(newValue);
            }
        };

        window.addEventListener('storage', onStorageChange);
        return () => {
            window.removeEventListener('storage', onStorageChange);
        };
    }, [value, setValue, storageKey, initialState]);

    return [value, setValue];
};
