/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import {
    KeyGetGetAllMissions,
    OnDemandReportDto,
    useMutationDeleteRemoveOnDemandBusReport,
    useMutationPostRequestOnDemandBusReport,
} from '../../../../../backend/gen';
import { FormattedMessage } from 'react-intl';
import React, { useCallback, useMemo, useState } from 'react';
import { Button, buttonClasses, DialogActions, styled } from '@mui/material';
import { TimeField } from '../../../../../components/form/TimeField/TimeField';
import { theme } from '../../../../../theme';
import { convertTimestampToHHMM } from '../../../../../utils/data.utils';
import { ButtonProps } from '@mui/material/Button';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { errorToastConfig, successToastConfig } from '../../../../../utils/constants';
import LoadingButton from '@mui/lab/LoadingButton';

export function BusRequestReportDialogContentAndActions({
    busName,
    report,
    closeDialog,
}: {
    busName: string;
    report: OnDemandReportDto;
    closeDialog: () => void;
}) {
    const queryClient = useQueryClient();

    const [updatedReport, setUpdatedReport] = useState<OnDemandReportDto>(report);
    const isEditingExistingReportRequest = useMemo(() => !!updatedReport?.id, [updatedReport?.id]);

    const { mutateAsync: submitRequest, isLoading: isSubmitLoading } = useMutationPostRequestOnDemandBusReport({
        onSuccess: async () => {
            await queryClient.invalidateQueries(KeyGetGetAllMissions);
            closeDialog();
            toast(
                <FormattedMessage
                    id={
                        isEditingExistingReportRequest
                            ? 'dispatcher.busRequestReport.editSuccess'
                            : 'dispatcher.busRequestReport.createSuccess'
                    }
                    values={{ bus: busName, time: convertTimestampToHHMM(updatedReport?.at) }}
                />,
                successToastConfig,
            );
        },
        onError: () => {
            toast(
                <FormattedMessage
                    id={
                        isEditingExistingReportRequest
                            ? 'dispatcher.busRequestReport.editFailure'
                            : 'dispatcher.busRequestReport.createFailure'
                    }
                    values={{ bus: busName }}
                />,
                errorToastConfig,
            );
        },
    });
    const { mutateAsync: deleteRequest, isLoading: isDeleteLoading } = useMutationDeleteRemoveOnDemandBusReport({
        onSuccess: async () => {
            await queryClient.invalidateQueries(KeyGetGetAllMissions);
            closeDialog();
            toast(
                <FormattedMessage id={'dispatcher.busRequestReport.deleteSuccess'} values={{ bus: busName }} />,
                successToastConfig,
            );
        },
        onError: () => {
            toast(
                <FormattedMessage id={'dispatcher.busRequestReport.deleteFailure'} values={{ bus: busName }} />,
                errorToastConfig,
            );
        },
    });

    const onClickSubmitRequest = useCallback(() => {
        if (!updatedReport?.at) return null;
        submitRequest({
            onDemandReportUpdateDto: {
                bus: busName,
                id: updatedReport?.id,
                at: convertTimestampToHHMM(updatedReport.at),
            },
        });
    }, [busName, submitRequest, updatedReport?.at, updatedReport?.id]);

    const onClickDeleteRequest = useCallback(() => {
        deleteRequest({
            id: updatedReport?.id,
        });
    }, [deleteRequest, updatedReport?.id]);

    return (
        <StyledContainer>
            <StyledRequestReportTimePicker
                name={'requestTime'}
                date={new Date()}
                value={updatedReport?.at || null}
                onChange={e => setUpdatedReport(prev => ({ ...prev, at: e }))}
                required
                label={<FormattedMessage id={'dispatcher.busRequestReport.time'} />}
            />
            <DialogActions>
                <Button {...CancellationButtonProps} onClick={closeDialog}>
                    <FormattedMessage id="confirm.default.cancel" />
                </Button>
                {updatedReport?.id && (
                    <LoadingButton {...DeletionButtonProps} onClick={onClickDeleteRequest} loading={isDeleteLoading}>
                        <FormattedMessage id="confirm.default.delete" />
                    </LoadingButton>
                )}
                <LoadingButton
                    {...ConfirmationButtonProps}
                    onClick={onClickSubmitRequest}
                    disabled={!updatedReport?.at}
                    loading={isSubmitLoading}
                >
                    <FormattedMessage id="confirm.default.confirm" />
                </LoadingButton>
            </DialogActions>
        </StyledContainer>
    );
}

const StyledContainer = styled('div')``;

const StyledRequestReportTimePicker = styled(TimeField)`
    & .MuiFilledInput-root {
        background-color: #f1f7fb !important;
    }
    & .MuiInputBase-input {
        color: ${theme.palette.blue.darkest} !important;
        ::-webkit-calendar-picker-indicator {
            filter: invert(0) !important;
        }
    }
`;

const BusRequestReportDialogButtonProps: Partial<ButtonProps> = {
    size: 'medium',
    sx: {
        borderRadius: '25px',
        padding: '16px 30px',
        letterSpacing: '0',
        textTransform: 'none',
        height: '50px',
        fontSize: '15px',
        lineHeight: '18px',
        whiteSpace: 'nowrap',
        [`&.${buttonClasses.text}`]: {
            border: '1px solid rgba(12, 69, 108, 0.5)',
        },
        [`&.${buttonClasses.outlined}`]: {
            color: theme => theme.palette.blue.lightest,
            borderColor: '#245f88',
        },
    },
};

const CancellationButtonProps: Partial<ButtonProps> = {
    ...BusRequestReportDialogButtonProps,
    sx: {
        ...BusRequestReportDialogButtonProps.sx,
        color: theme.palette.blue.dark,
    },
    variant: 'text',
};

const DeletionButtonProps: Partial<ButtonProps> = {
    ...BusRequestReportDialogButtonProps,
    sx: {
        ...BusRequestReportDialogButtonProps.sx,
        color: theme.palette.error.main,
        [`&.MuiButton-text`]: {
            border: `1px solid ${theme.palette.error.main}`,
        },
    },
    variant: 'text',
};

const ConfirmationButtonProps: Partial<ButtonProps> = {
    ...BusRequestReportDialogButtonProps,
    sx: {
        ...BusRequestReportDialogButtonProps.sx,
        [`&.Mui-disabled`]: {
            backgroundColor: theme.palette.blue.lightest,
        },
    },
    variant: 'contained',
};
